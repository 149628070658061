var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v(" The information you received from the client analysed, recommendations provided ")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.complianceCheckList.isAnalysed,
            callback: function callback($$v) {
              _vm.$set(_vm.complianceCheckList, "isAnalysed", $$v);
            },
            expression: "complianceCheckList.isAnalysed"
          }
        }, _vm._l(_vm.checklistOptions, function (checklistItem, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": checklistItem.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(checklistItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v("Benefits and risks were explained to the client")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.complianceCheckList.isExplained,
            callback: function callback($$v) {
              _vm.$set(_vm.complianceCheckList, "isExplained", $$v);
            },
            expression: "complianceCheckList.isExplained"
          }
        }, _vm._l(_vm.checklistOptions, function (checklistItem, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": checklistItem.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(checklistItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v("Important notes on the application are up to date")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.complianceCheckList.isNotesUpToDate,
            callback: function callback($$v) {
              _vm.$set(_vm.complianceCheckList, "isNotesUpToDate", $$v);
            },
            expression: "complianceCheckList.isNotesUpToDate"
          }
        }, _vm._l(_vm.checklistOptions, function (checklistItem, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": checklistItem.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(checklistItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v("Finial SOA was provided and signed or acknowledged")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.complianceCheckList.isSOA,
            callback: function callback($$v) {
              _vm.$set(_vm.complianceCheckList, "isSOA", $$v);
            },
            expression: "complianceCheckList.isSOA"
          }
        }, _vm._l(_vm.checklistOptions, function (checklistItem, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": checklistItem.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(checklistItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v(" Has each client consented to their records and personal information being viewed by FMA? ")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.complianceCheckList.isConsented,
            callback: function callback($$v) {
              _vm.$set(_vm.complianceCheckList, "isConsented", $$v);
            },
            expression: "complianceCheckList.isConsented"
          }
        }, _vm._l(_vm.checklistOptions, function (checklistItem, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": checklistItem.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(checklistItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v(" If there is a complaint, did you document and attached the evidence that the problem was resolved? ")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.complianceCheckList.isComplaint,
            callback: function callback($$v) {
              _vm.$set(_vm.complianceCheckList, "isComplaint", $$v);
            },
            expression: "complianceCheckList.isComplaint"
          }
        }, _vm._l(_vm.checklistOptions, function (checklistItem, idx) {
          return _c('v-radio', {
            key: idx,
            attrs: {
              "value": checklistItem.value,
              "disabled": _vm.FIELDS_DISABLED
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(checklistItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1)], 1)])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }