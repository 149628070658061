<template>
  <div>
    <BaseSection
      :id="section.key"
      :is-visible="isSectionVisible"
      :is-modified="isDataChanged"
      :section="section"
      @onSectionSaveClick="save"
    >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-radio-group
            :row="true"
            v-model="complianceCheckList.isAnalysed"
          >
            <template #label>
              <div>
                  <h3>
                    The information you received from the client analysed, recommendations provided
                  </h3>
              </div>
            </template>
            <v-radio
              v-for="(checklistItem, idx) in checklistOptions"
              :value="checklistItem.value"
              :key="idx"
              :disabled="FIELDS_DISABLED"
            >
              <template #label>
                <div>{{ checklistItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-radio-group
            :row="true"
            v-model="complianceCheckList.isExplained"
          >
            <template #label>
              <div>
                <h3>Benefits and risks were explained to the client</h3>
              </div>
            </template>
            <v-radio
              v-for="(checklistItem, idx) in checklistOptions"
              :value="checklistItem.value"
              :key="idx"
              :disabled="FIELDS_DISABLED"
            >
              <template #label>
                <div>{{ checklistItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-radio-group
            :row="true"
            v-model="complianceCheckList.isNotesUpToDate"
          >
            <template #label>
              <div>
                <h3>Important notes on the application are up to date</h3>
              </div>
            </template>
            <v-radio
              v-for="(checklistItem, idx) in checklistOptions"
              :value="checklistItem.value"
              :key="idx"
              :disabled="FIELDS_DISABLED"
            >
              <template #label>
                <div>{{ checklistItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-radio-group
            :row="true"
            v-model="complianceCheckList.isSOA"
          >
            <template #label>
              <div>
                <h3>Finial SOA was provided and signed or acknowledged</h3>
              </div>
            </template>
            <v-radio
              v-for="(checklistItem, idx) in checklistOptions"
              :value="checklistItem.value"
              :key="idx"
              :disabled="FIELDS_DISABLED"
            >
              <template #label>
                <div>{{ checklistItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-radio-group
            :row="true"
            v-model="complianceCheckList.isConsented"
          >
            <template #label>
              <div>
                <h3>
                  Has each client consented to their records
                  and personal information being viewed by FMA?
                </h3>
              </div>
            </template>
              <v-radio
                v-for="(checklistItem, idx) in checklistOptions"
                :value="checklistItem.value"
                :key="idx"
                :disabled="FIELDS_DISABLED"
              >
                <template #label>
                  <div>{{ checklistItem.text }}</div>
                </template>
              </v-radio>
          </v-radio-group>
          <v-radio-group
            :row="true"
            v-model="complianceCheckList.isComplaint"
          >
            <template #label>
              <div>
                <h3>
                  If there is a complaint, did you document and
                  attached the evidence that the problem was resolved?
                </h3>
              </div>
            </template>
              <v-radio
                v-for="(checklistItem, idx) in checklistOptions"
                :value="checklistItem.value"
                :key="idx"
                :disabled="FIELDS_DISABLED"
              >
                <template #label>
                  <div>{{ checklistItem.text }}</div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
        </v-card-text>
      </template>
    </BaseSection>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'ComplianceChecklist',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,
      checklistOptions: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      complianceCheckList: {
        isAnalysed: '',
        isExplained: '',
        isNotesUpToDate: '',
        isSOA: '',
        isConsented: '',
        isComplaint: '',
      },
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },

  async created() {
    await this.checkIsSectionVisible();
    this.initialInsurance = clone(this.existedData);
    this.complianceCheckList = clone(this.existedData);

    this.$emit('componentReady');
  },
};
</script>
